import { createSlice } from '@reduxjs/toolkit';
import { setState } from '@app/helpers/store';

const INITIAL_STATE = {
  modalIsOpen: false,
  modalType: null,
  modalTitle: null,
  modalContent: null,
  askBennieModalIsVisible: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState: INITIAL_STATE,
  reducers: {
    onModalOpen(
      state,
      {
        payload: { modalType, modalTitle, modalContent },
      },
    ) {
      setState(state, {
        modalIsOpen: true,
        modalType,
        modalTitle,
        modalContent,
      });
    },
    onModalClose: () => {
      return INITIAL_STATE;
    },
    onModalCancel: () => {
      return INITIAL_STATE;
    },
    setAskBennieModalVisibility(
      state,
      {
        payload: { isVisible },
      },
    ) {
      setState(state, { askBennieModalIsVisible: isVisible });
    },
  },
});

const { actions, reducer } = modalSlice;

export const {
  onModalOpen,
  onModalClose,
  onModalCancel,
  setAskBennieModalVisibility,
} = actions;

export default reducer;
