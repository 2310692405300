import { auth } from '@benniehealth/core-js';
import { takeLatest } from 'redux-saga/effects';

import * as analytics from '@app/helpers/analytics';

export function* onLogoutSuccess() {
  analytics.reset();
}

export function* logoutWatcher() {
  yield takeLatest(auth.actions.authOktaLogOutSuccess, onLogoutSuccess);
}
