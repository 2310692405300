import { styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';

import BennieBlueLogoSvg from '@app/images/svg/logo_bennie_blue.svg';

const Root = styled('div')({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `20px`,
  width: `420px`,
  alignSelf: `center`,
  height: `100vh`,
  margin: `0 auto`,
  '& .content': {
    height: `200px`,
  },
  '& .logo': {
    width: `189px`,
    height: `auto`,
    alignSelf: `baseline`,
    objectFit: `contain`,
    marginLeft: `-6px`,
    marginBottom: `16px`,
  },
});

const MaintenancePage = () => {
  return (
    <Root>
      <div className="content">
        <SvgIcon
          component={BennieBlueLogoSvg}
          viewBox="0 0 360 114"
          className="logo"
        />
        <p>
          Bennie is undergoing maintenance at the moment. <br />
          Please check back later. We&apos;re sorry for any inconvenience.
        </p>
      </div>
    </Root>
  );
};

export default MaintenancePage;
