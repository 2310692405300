/* eslint-disable camelcase */
import qs from 'qs';
import { DEFAULT_PROXIMITY } from '@app/constants/findADoctor';
import I18n from '@app/i18n';
import { coreClient as client } from '@app/helpers/requests/client';
import {
  getResponseData,
  responseFailed,
} from '@app/helpers/requests/bennieFetch';

const stringifyParams = params => qs.stringify(params);

const PRIMARY_CARE_PROVIDER = 'Primary Care Provider';

const requiredProvidersFields = [
  'npi',
  'first_name',
  'middle_name',
  'last_name',
  'degrees',
  'distance',
  'ratings_count',
  'ratings_avg',
  'insurances.uuid',
  'specialties.uuid',
  'specialties.provider_name',
  'locations.uuid',
  'locations.address',
  'locations.distance',
  'locations.latitude',
  'locations.longitude',
  'locations.confidence',
].join(',');

export const searchProviders = async params => {
  const {
    address,
    specialty,
    specialty_id,
    ratings,
    language,
    gender,
    insurance_ids,
    out_of_network,
    page,
    location,
    name,
  } = params;

  const shouldShowUiErrorStatusCodes = [429, 500, 400];

  const specialtyOrSpecialtyIdsParams =
    specialty === PRIMARY_CARE_PROVIDER
      ? { specialty }
      : { specialty_ids: specialty_id };

  const queryStringParams = stringifyParams({
    fields: requiredProvidersFields,
    distance: DEFAULT_PROXIMITY,
    page_size: 20,
    min_location_confidence: 3,
    location_within_distance: true,
    ...(!!ratings && { min_rating: +ratings / 10 }),
    ...(!!page && { page }),
    ...(!!ratings && { min_ratings_count: 1 }),
    ...(!!address && { address }),
    ...specialtyOrSpecialtyIdsParams,
    ...(!!language && { language }),
    ...(!!gender && { gender }),
    ...(!!location && { location }),
    ...(!!name && { name }),
    ...(!!insurance_ids &&
      out_of_network === 'false' && {
        location_insurance_ids: insurance_ids,
      }),
  });

  return client.get(`/v1/providers?${queryStringParams}`).then(response => {
    if (
      shouldShowUiErrorStatusCodes.includes(+response.status) ||
      responseFailed(response.status)
    ) {
      throw new Error(`${I18n.t(`findADoctor.error.errorFetchingProviders`)}`);
    }

    return getResponseData(response);
  });
};

export const fetchProvider = npi => {
  let responseStatus;

  return client
    .get(`/v1/providers/${npi}`)
    .then(response => {
      responseStatus = response.status;

      return getResponseData(response);
    })
    .then(data => ({ ...data, responseStatus }));
};

export const searchSpecialties = (params, uri = `/v1/specialties`) => {
  const queryStringParams = stringifyParams({ page_size: 2000, ...params });

  return client
    .get(`${uri}?${queryStringParams}`)
    .then(response => getResponseData(response));
};
