/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';

import {
  messages,
  claims,
  marketplace,
  perks,
  profile,
  postLoginExperience,
  blogPosts,
  auth,
} from '@benniehealth/core-js';
import { insights } from '@benniehealth/insights-js';
import findADoctor from '@app/store/findADoctor';
import findMedicalCare from '@app/store/findMedicalCare';
import location from '@app/store/location';
import enrollmentDocuments from '@app/store/enrollmentDocuments';
import modal from '@app/store/modal';
import admin from '@app/store/admin';
import companyAccesses from '@app/store/companyAccesses';
import clientAttributes from '@app/store/clientAttributes';
import telehealth from '@app/store/telehealth';
import sessions from '@app/store/sessions';
import staticConfigs from '@app/store/staticConfigs/reducer';
import snackbar from '@app/store/snackbar';
import appState from '@app/store/appState';
import { bennieApi } from '@app/store/bennieApi';
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    findADoctor,
    findMedicalCare,
    telehealth,
    profile: profile.slice,
    modal,
    auth: auth.slice,
    location,
    admin,
    enrollmentDocuments,
    companyAccesses,
    clientAttributes,
    marketplace: marketplace.slice,
    messages: messages.slice,
    claims: claims.slice,
    sessions,
    staticConfigs,
    snackbar,
    perks: perks.slice,
    postLoginExperience: postLoginExperience.slice,
    blogPosts: blogPosts.slice,
    appState,
    insights: insights.slice,
    [bennieApi.reducerPath]: bennieApi.reducer,
    ...injectedReducers,
  });

  return rootReducer;
}
