import { put, takeLatest, call } from 'redux-saga/effects';
import _get from 'lodash/get';

import I18n from '@app/i18n';
import { coreClient as client } from '@app/helpers/requests/client';
import { getResponseData } from '@app/helpers/requests/bennieFetch';
import {
  checkLocationPermissionsRequest,
  checkLocationPermissionsPrompt,
  checkLocationPermissionsSuccess,
  checkLocationPermissionsDenied,
  setCurrentLocationError,
  setCurrentLocationSuccess,
  setPostalCodeRequest,
  setPostalCodeSuccess,
} from './index';

function reverseLookupPostal({ latitude, longitude }) {
  return client(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${
      process.env.GOOGLE_MAPS_KEY
    }&latlng=${latitude},${longitude}&result_type=postal_code`,
    { baseURL: '' },
  ).then(response => getResponseData(response));
}

function getLocationFromResults(results) {
  return results[0]?.address_components?.[0]?.long_name || null;
}

function* getPostalFromCoords({ latitude, longitude }) {
  yield put(setPostalCodeRequest());
  try {
    const reverseLookup = yield reverseLookupPostal({ latitude, longitude });
    const postalCode = getLocationFromResults(reverseLookup.results);
    yield put(setPostalCodeSuccess({ postalCode }));
  } catch (error) {
    yield put(
      setCurrentLocationError(
        error.message ||
          I18n.t(`findADoctor.error.errorGettingPostalFromCoordinates`),
      ),
    );
  }
}

const getUserLocation = options =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      location => resolve(location),
      error => reject(error),
      { ...options },
    );
  });

function* handlePermissions() {
  try {
    yield put(checkLocationPermissionsPrompt());
    const getLocation = yield call(getUserLocation, {
      enableHighAccuracy: true,
    });
    const latitude = _get(getLocation, 'coords.latitude');
    const longitude = _get(getLocation, 'coords.longitude');

    if (!latitude || !longitude) {
      throw new Error(
        I18n.t(`findADoctor.error.errorGettingLocationFromBrowser`),
      );
    }

    yield put(checkLocationPermissionsSuccess());
    yield put(setCurrentLocationSuccess({ latitude, longitude }));
    yield* getPostalFromCoords({ latitude, longitude });
  } catch (error) {
    yield put(checkLocationPermissionsDenied());
    yield put(
      setCurrentLocationError(
        error.message ||
          I18n.t(`findADoctor.error.errorGettingLocationFromBrowser`),
      ),
    );
  }
}

export function* checkLocationPermissionsWatcher() {
  yield takeLatest(checkLocationPermissionsRequest, handlePermissions);
}
