import { put, takeLatest } from 'redux-saga/effects';
import qs from 'qs';

import { coreClient as client } from '@app/helpers/requests/client';
import {
  getResponseData,
  responseSuccess,
} from '@app/helpers/requests/bennieFetch';
import { fetchUsersRequest, fetchUsersSuccess, fetchUsersError } from './index';

export function* requestUsers({ payload: { params } }) {
  // backend is Zero-based
  const pageNumber = params.page ? +params.page - 1 : 0;

  const queryParams = {
    name: params.name,
    pageNumber,
  };

  try {
    const response = yield client
      .get(`/v1/admin/users/listusers?${qs.stringify(queryParams)}`)
      .catch(error => {
        throw new Error(error);
      });

    let data;

    if (response.status >= 200 && response.status < 300) {
      const { status } = response;

      if (responseSuccess(status)) {
        data = yield getResponseData(response);
      } else if (response.status === 204) {
        data = {};
      }

      yield put(
        fetchUsersSuccess({
          response: data,
          params,
        }),
      );
    } else {
      throw new Error('Error fetching users');
    }
  } catch (error) {
    yield put(
      fetchUsersError({
        error: error.message || 'not allowed',
      }),
    );
  }
}

export function* adminRequestUsersWatcher() {
  yield takeLatest(fetchUsersRequest, requestUsers);
}
