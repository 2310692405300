import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';

import { isProd } from '@app/helpers/environment';

import en from './locales/en.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: !isProd(window.location.hostname),
  interpolation: {
    escapeValue: false, // not needed for React as it escapes by default
  },
  returnObject: true, // allows objects as valid translation result
  parseMissingKeyHandler: () => undefined, // this makes it safe to do dynamic lookup, so it won't output the 'missing translation' string
  supportedLngs: ['en', 'en-US'],
  resources: {
    en: {
      translation: en,
    },
  },
});

export default {
  useTranslation,
  t: (key, options) => i18n.t(key, options),
};
