import qs from 'qs';
import I18n from '@app/i18n';
import { coreClient as client } from '@app/helpers/requests/client';
import {
  getResponseData,
  responseDataAdapter,
  responseFailed,
} from '@app/helpers/requests/bennieFetch';

export const handleErrors = response => {
  const { status } = response;

  if (status === 400) {
    const { errors } = getResponseData(response);

    throw Error(
      Object.entries(errors)
        .reduce((acc, curr) => {
          const errorField = I18n.t(`perksScreen.${curr[0]}`);
          const message = errorField ? `${errorField}: ${curr[1]}` : curr[1];
          return [...acc, message];
        }, [])
        .join('\n'),
    );
  }

  throw Error(
    'We encountered an error processing your request. Please try again later.',
  );
};

export const fetchAdminPerks = payload => {
  const queryString =
    qs.stringify(payload.params, { addQueryPrefix: true }) || '';

  return client
    .get(`/v1/admin/perks${queryString}`)
    .then(response => responseDataAdapter(response));
};

export const fetchAdminArchivedPerks = payload => {
  const queryString =
    qs.stringify(
      { ...payload.params, status: 'archived' },
      { addQueryPrefix: true },
    ) || '';

  return client
    .get(`/v1/admin/perks${queryString}`)
    .then(response => responseDataAdapter(response));
};

export const deleteAdminPerk = ({ perkId }) => {
  return client.delete(`/v1/admin/perks/${perkId}`);
};

export const createAdminPerk = payload => {
  return client.post(`/v1/admin/perks`, { ...payload }).then(response => {
    const { status } = response;

    if (responseFailed(status)) {
      return handleErrors(response);
    }

    return getResponseData(response);
  });
};

export const uploadAdminPerkDocument = async (perkId, payload) => {
  const data = {
    PerkId: perkId,
    FileName: payload.fileName,
    File: payload.file,
  };

  const response = await client.post(
    `/v1/admin/perks/documents`,
    { ...data },
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );

  const { status } = response;

  if (responseFailed(status)) {
    return handleErrors(response);
  }

  return getResponseData(response);
};

export const fetchAdminPerk = payload => {
  const { perkId } = payload;

  return client
    .get(`/v1/admin/perks/${perkId}`)
    .then(response => responseDataAdapter(response));
};

export const requestAdminPerkDocument = payload => {
  const { documentId } = payload;

  return client
    .get(`/v1/admin/perks/documents/${documentId}`, { responseType: 'blob' })
    .then(response => getResponseData(response))
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
};

export const updateAdminPerk = payload => {
  const { id: perkId } = payload;

  return client
    .put(`/v1/admin/perks/${perkId}`, { ...payload })
    .then(response => {
      const { status } = response;

      if (responseFailed(status)) {
        return handleErrors(response);
      }

      return getResponseData(response);
    });
};

export const deleteAdminPerkDocument = documentId => {
  return client
    .delete(`/v1/admin/perks/documents/${documentId}`)
    .then(response => {
      const { status } = response;

      if (responseFailed(status)) {
        return handleErrors(response);
      }

      return getResponseData(response);
    });
};
