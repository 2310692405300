import { coreClient as client } from '@app/helpers/requests/client';
import {
  getResponseData,
  responseDataAdapter,
} from '@app/helpers/requests/bennieFetch';

export const fetchPerks = () =>
  client.get(`/v1/perks`).then(response => responseDataAdapter(response));

export const requestPerkDocument = payload => {
  const { documentId } = payload;

  return client
    .get(`/v1/perks/documents/${documentId}`, {
      responseType: 'blob',
    })
    .then(response => getResponseData(response))
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
};

export const fetchMetadata = async params => {
  return client.get(`/v1/sitedata?${params}`).then(response => response);
};
