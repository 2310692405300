import {
  getResponseData,
  queryParams,
  responseDataAdapter,
} from '@app/helpers/requests/bennieFetch';
import { coreClient as client } from '@app/helpers/requests/client';

const DEFAULT_ITEMS_PER_PAGE = 30;
const DEFAULT_PAGE_COMMENTS_PAGINATION = 1;

const handleErrors = ({ error, customMessage }) => {
  const { response, message } = error;
  const responseStatus = response?.status;

  const statusCode = parseInt(responseStatus, 10);

  if (statusCode === 400) {
    const { errors } = getResponseData(response);

    if (errors) {
      const errorsString = Object.values(errors).join('\n');
      throw Error(errorsString);
    }

    throw Error(customMessage || message);
  }

  if (statusCode === 404) {
    throw Error(customMessage || message);
  }

  throw Error(
    'We encountered an error processing your request. Please try again later.',
  );
};

const uploadFile = file => {
  const data = { file };

  return client
    .post(
      `/v1/askbennie/uploads`,
      { ...data },
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
    .then(response => getResponseData(response))
    .catch(err => handleErrors({ error: err }));
};

export const createTicket = async params => {
  const {
    customFields,
    message,
    subject,
    attachment,
    viaFollowupSourceId,
  } = params;

  let uploads = [];

  if (attachment) {
    const uploadResponse = await uploadFile(attachment);
    uploads = [uploadResponse.upload.token];
  }

  return client
    .post(`/v1/askbennie/tickets`, {
      request: {
        subject: subject || 'No Subject Provided',
        comment: {
          uploads,
          body: message || 'No message added',
        },
        custom_fields: Object.keys(customFields).map(key => {
          return {
            id: key,
            value: customFields[key].id
              ? customFields[key].value
              : customFields[key],
          };
        }),
        via_followup_source_id: viaFollowupSourceId || undefined,
      },
    })
    .then(response => responseDataAdapter(response))
    .catch(err =>
      handleErrors({ error: err, customMessage: 'Unable to create ticket' }),
    );
};

export const updateTicket = async params => {
  const { id, message, attachment } = params;

  let uploads = [];

  if (attachment) {
    const uploadResponse = await uploadFile(attachment);
    uploads = [uploadResponse.upload.token];
  }

  return client
    .put(`/v1/askbennie/tickets/${id}`, {
      request: { comment: { body: message, uploads } },
    })
    .then(response => responseDataAdapter(response))
    .catch(err =>
      handleErrors({ error: err, customMessage: 'Unable to update ticket' }),
    );
};

export const fetchTickets = params => {
  const { status, perPage, page } = params;

  return client
    .get(
      `/v1/askbennie/tickets?${queryParams({
        per_page: perPage || DEFAULT_ITEMS_PER_PAGE,
        sort_by: 'updated_at',
        sort_order: 'desc',
        status,
        page,
      })}`,
    )
    .then(response => responseDataAdapter(response));
};

export const fetchOpenTickets = (params = {}) =>
  fetchTickets({ ...params, status: 'open,hold,new,pending' });

export const fetchResolvedTickets = (params = {}) =>
  fetchTickets({ ...params, status: 'solved,closed' });

export const fetchComments = params => {
  const { id, page, perPage } = params;

  return client
    .get(
      `/v1/askbennie/tickets/${id}/comments?${queryParams({
        per_page: perPage,
        page: page || DEFAULT_PAGE_COMMENTS_PAGINATION,
        sort_order: 'desc',
      })}`,
    )
    .then(response => responseDataAdapter(response));
};

export const fetchTicket = async params =>
  client
    .get(`/v1/askbennie/tickets/${params.id}`)
    .then(response => responseDataAdapter(response));

export const fetchAgents = async () => {
  return client
    .get(`/v1/askbennie/users`)
    .then(response => responseDataAdapter(response));
};

export const fetchTicketFields = async () => {
  return client
    .get(`/v1/askbennie/fields`)
    .then(response => responseDataAdapter(response));
};

export const fetchZendeskUser = async () => {
  return client
    .post(`/v1/askbennie/auth`)
    .then(response => responseDataAdapter(response));
};
