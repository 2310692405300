import { put, takeLatest } from 'redux-saga/effects';

import * as api from '@app/api/telehealthConfiguration';

import {
  getTelehealthConfiguration,
  getTelehealthConfigurationSuccess,
  getTelehealthConfigurationError,
} from './index';

function* sagaTelehealthConfiguration() {
  try {
    const response = yield api.fetchTelehealthConfiguration();
    yield put(getTelehealthConfigurationSuccess(response));
  } catch ({ message }) {
    yield put(
      getTelehealthConfigurationError(
        message || 'Failed to fetch telehealth configuration...',
      ),
    );
  }
}

export function* getTelehealthConfigurationWatcher() {
  yield takeLatest(getTelehealthConfiguration, sagaTelehealthConfiguration);
}
