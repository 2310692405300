import { put, takeEvery } from 'redux-saga/effects';
import { coreClient as client } from '@app/helpers/requests/client';
import {
  getResponseData,
  responseSuccess,
} from '@app/helpers/requests/bennieFetch';

import {
  fetchClientAttributesRequest,
  fetchClientAttributesSuccess,
  fetchClientAttributesError,
} from './index';

export function* fetchClientAttributes() {
  try {
    const response = yield client(`/v1/profile/clientattributes`);

    const { status } = response;

    if (!responseSuccess(status)) {
      throw Error('Could not get client attributes');
    }
    yield put(fetchClientAttributesSuccess(getResponseData(response)));
  } catch (error) {
    yield put(fetchClientAttributesError(error.message));
  }
}

export function* fetchClientAttributesWatcher() {
  yield takeEvery(fetchClientAttributesRequest, fetchClientAttributes);
}
