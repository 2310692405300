import { put, takeLatest } from 'redux-saga/effects';

import * as api from '@app/api/ribbonHealth/ribbonHealth';
import * as savingApi from '@app/api/savedProviders';
import { normalizeProvider } from '@app/api/ribbonHealth/helpers';
import I18n from '@app/i18n';
import {
  fetchProviderRequest,
  fetchProviderSuccess,
  fetchProviderError,
  fetchSavedProvidersRequest,
  fetchSavedProvidersSuccess,
  fetchSavedProvidersError,
  saveProviderSuccess,
  saveProviderError,
  saveProviderRequest,
  deleteProviderRequest,
  deleteProviderError,
  deleteProviderSuccess,
} from './index';

export function* fetchProvider({ payload: { npi } }) {
  let shouldDeleteProvider = false;

  try {
    const result = yield api.fetchProvider(npi);

    /* if provider no longer exists */
    if (result.respStatusCode === 400) {
      shouldDeleteProvider = true;

      throw new Error(`${I18n.t(`findADoctor.error.errorFetchingProvider`)}`);
    }

    yield put(
      fetchProviderSuccess({
        appointmentProvider: normalizeProvider(result),
      }),
    );
  } catch (error) {
    yield put(
      fetchProviderError({
        errorFetchingProvider: error.message || 'Could not get provider',
        shouldDeleteProvider,
      }),
    );
  }
}

export function* saveProvider({ payload: { provider } }) {
  try {
    const savedProvider = yield savingApi.saveProvider(provider);

    yield put(saveProviderSuccess({ provider: savedProvider }));
  } catch (error) {
    yield put(
      saveProviderError({
        errorSavingProvider: error.message || 'Could not save provider',
      }),
    );
  }
}

export function* fetchSavedProviders() {
  try {
    const savedProviders = yield savingApi.fetchSavedProviders();

    yield put(fetchSavedProvidersSuccess({ savedProviders }));
  } catch (error) {
    yield put(
      fetchSavedProvidersError({
        errorFetchingSavedProviders:
          error.message || 'Could not fetch saved providers',
      }),
    );
  }
}

export function* deleteProvider({ payload: { id } }) {
  try {
    yield savingApi.deleteSavedProvider(id);

    yield put(deleteProviderSuccess({ id }));
  } catch (error) {
    yield put(
      deleteProviderError({
        errorDeletingSavedProvider:
          error.message || 'Could not delete saved provider',
      }),
    );
  }
}

export function* getProviderWatcher() {
  yield takeLatest(fetchProviderRequest, fetchProvider);
}

export function* saveProviderWatcher() {
  yield takeLatest(saveProviderRequest, saveProvider);
}

export function* fetchSavedProvidersWatcher() {
  yield takeLatest(fetchSavedProvidersRequest, fetchSavedProviders);
}

export function* deleteProviderWatcher() {
  yield takeLatest(deleteProviderRequest, deleteProvider);
}
