import FIELD_SLUGS from './fieldSlugs';

const TOPIC_FIELD_ID = 360038690311;
export default {
  customFields: {
    [TOPIC_FIELD_ID]: FIELD_SLUGS.TOPIC,
    360038690331: FIELD_SLUGS.SPECIALTY,
    360038690491: FIELD_SLUGS.LOCATION,
    360038775132: FIELD_SLUGS.PROXIMITY,
    360038775172: FIELD_SLUGS.REASON,
    360038690691: FIELD_SLUGS.PROVIDER,
    360041644511: FIELD_SLUGS.TIME, // new field to eventually replace original time field
    360041673771: FIELD_SLUGS.DATE, // new field to eventually replace original date field
    360041732072: FIELD_SLUGS.NEW_PATIENT,
    17409310878093: FIELD_SLUGS.PARENT_TICKET_ID, // follow-up feature
  },
  legacyFields: {
    360038775152: FIELD_SLUGS.DATE,
    360038690671: FIELD_SLUGS.TIME,
  },
  activeFieldOptions: {
    [TOPIC_FIELD_ID]: [
      360073365852, // benefits and coverage
      360074720851, // claims
      360073365932, // authorizations and appeals
      360073365972, // provider help
      360073365872, // scheduling appointments
      360073365952, // pharmacy
      360073365912, // other
    ],
  },
};
