import { coreClient as client } from '@app/helpers/requests/client';
import {
  getResponseData,
  responseSuccess,
} from '@app/helpers/requests/bennieFetch';

// https://bennie.stoplight.io/docs/core-api/b996a20d6750e-gets-sso-config-for-company
export const getSSOConfig = () => {
  return client
    .get(`/v1/admin/sso/saml`)
    .then(response => getResponseData(response));
};

// https://bennie.stoplight.io/docs/core-api/8402ae2c57dde-validates-saml-file-and-creates-idp-in-okta
export const createSSOConfig = ({ idp, file }) => {
  const data = {
    idpType: idp,
    file,
  };

  return client
    .post(
      `/v1/admin/sso/saml`,
      { ...data },
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
    .then(response => {
      const responseData = getResponseData(response);

      const { status } = response;

      if (responseSuccess(status)) {
        return responseData;
      }

      const errorMessage = responseData?.errors?.join(' ');

      throw new Error(errorMessage);
    });
};

// https://bennie.stoplight.io/docs/core-api/2de0d5777ec25-deletes-sso-configuration-for-a-company
export const deleteSSOConfig = () => {
  return client.delete(`/v1/admin/sso/saml`);
};

// https://bennie.stoplight.io/docs/core-api/15bbf19057f5d-enables-sso-for-current-company
export const enableSSOConfig = () => {
  return client.post(`/v1/admin/sso/saml/enable`);
};
