import { coreClient as client } from '@app/helpers/requests/client';
import {
  responseDataAdapter,
  responseFailed,
} from '@app/helpers/requests/bennieFetch';

export const fetchEmployeeMarketplace = () => {
  return client
    .get(`/api/marketplace-partners/employee-marketplace-partners`, {
      baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL,
    })
    .then(response => {
      const { status } = response;

      if (responseFailed(status)) {
        throw new Error('Could not fetch employee marketplace partners');
      }

      return responseDataAdapter(response);
    });
};

export const fetchEmployerMarketplace = () => {
  return client
    .get(`/api/marketplace-partners/employer-marketplace-partners`, {
      baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL,
    })
    .then(response => {
      const { status } = response;

      if (responseFailed(status)) {
        throw new Error('Could not fetch employer marketplace partners');
      }

      return responseDataAdapter(response);
    });
};

export const sendContactRequest = partnerName => {
  return client
    .post(`/v1/admin/employermarketplace/contact`, {
      partnerName,
    })
    .then(response => {
      const { status } = response;

      if (responseFailed(status)) {
        throw new Error('Could not complete request');
      }

      return responseDataAdapter(response);
    });
};
