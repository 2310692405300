import I18n from '@app/i18n';
import { USER_ROLES } from '@app/constants/user';

const HR_ADMIN_ACCESS_DISPLAY = I18n.t(`accessLevels.hrAdmin`);

const EMPLOYEE_ACCESS_DISPLAY = I18n.t(`accessLevels.employee`);

const DEPENDENT_ACCESS_DISPLAY = I18n.t(`accessLevels.dependent`);

const SYSTEM_ADMIN_DISPLAY = I18n.t(`accessLevels.systemAdmin`);

export const ACCESS_LEVEL_OPTIONS = [
  {
    id: USER_ROLES.HR_ADMIN,
    display: HR_ADMIN_ACCESS_DISPLAY,
  },
  {
    id: USER_ROLES.SYSTEM_ADMIN,
    display: SYSTEM_ADMIN_DISPLAY,
  },
  {
    id: USER_ROLES.EMPLOYEE,
    display: EMPLOYEE_ACCESS_DISPLAY,
  },
  {
    id: USER_ROLES.DEPENDENT,
    display: DEPENDENT_ACCESS_DISPLAY,
  },
];
