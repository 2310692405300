import { put, select, takeLatest } from 'redux-saga/effects';
import { profile } from '@benniehealth/core-js';

import * as api from '@app/api/ribbonHealth/ribbonHealthV2';
import * as savingApi from '@app/api/savedProviders';
import * as savingFacilitiesApi from '@app/api/savedFacilities';
import {
  normalizeLocation,
  normalizeProvider,
  normalizeResultsPagination,
  normalizeSearchResults,
} from '@app/api/ribbonHealth/helpers';
import I18n from '@app/i18n';
import { SEARCH_TYPES } from '@app/constants/findMedicalCare';
import { getSavedItemIds } from './selectors';
import {
  deleteFacilityError,
  deleteFacilityRequest,
  deleteFacilitySuccess,
  deleteProviderError,
  deleteProviderRequest,
  deleteProviderSuccess,
  fetchItemError,
  fetchItemRequest,
  fetchItemSuccess,
  fetchSavedFacilitiesError,
  fetchSavedFacilitiesRequest,
  fetchSavedFacilitiesSuccess,
  fetchSavedProvidersError,
  fetchSavedProvidersRequest,
  fetchSavedProvidersSuccess,
  saveFacilityError,
  saveFacilityRequest,
  saveFacilitySuccess,
  saveProviderError,
  saveProviderRequest,
  saveProviderSuccess,
  searchError,
  searchRequest,
  searchSuccess,
} from './index';

function* search(action) {
  const { payload } = action;
  const state = yield select();
  const savedItemIds = getSavedItemIds(state);
  const planNetworkMaps = yield select(
    profile.selectors.mdvPlanNetworkMapsSelector,
  );

  try {
    const { data: results, parameters } = yield api.search(payload);

    yield put(
      searchSuccess({
        results: normalizeSearchResults(
          payload.type,
          results,
          planNetworkMaps,
          savedItemIds,
        ),
        pagination: normalizeResultsPagination(parameters),
      }),
    );
  } catch (error) {
    yield put(searchError({ error }));
  }
}

function* fetchSavedFacilities() {
  try {
    const savedFacilities = yield savingFacilitiesApi.fetchSavedFacilities();

    yield put(fetchSavedFacilitiesSuccess({ savedFacilities }));
  } catch (error) {
    yield put(
      fetchSavedFacilitiesError({
        errorFetchingSavedFacilities:
          error.message || 'Could not fetch saved facilities',
      }),
    );
  }
}

export function* fetchSavedFacilitiesWatcher() {
  yield takeLatest(fetchSavedFacilitiesRequest, fetchSavedFacilities);
}

function* saveFacility({ payload: { facility } }) {
  try {
    const savedFacility = yield savingFacilitiesApi.saveFacility(facility);

    yield put(
      saveFacilitySuccess({
        facility: {
          ...facility,
          id: savedFacility.id,
          displayName: savedFacility.name,
        },
      }),
    );
  } catch (error) {
    yield put(
      saveFacilityError({
        errorSavingFacility: error.message || 'Could not save facility',
      }),
    );
  }
}

export function* searchWatcher() {
  yield takeLatest(searchRequest, search);
}

export function* saveFacilityWatcher() {
  yield takeLatest(saveFacilityRequest, saveFacility);
}

function* deleteFacility({ payload: { id } }) {
  try {
    yield savingFacilitiesApi.deleteSavedFacility(id);

    yield put(deleteFacilitySuccess({ id }));
  } catch (error) {
    yield put(
      deleteFacilityError({
        errorDeletingSavedFacility:
          error.message || 'Could not delete saved facility',
      }),
    );
  }
}

export function* deleteFacilityWatcher() {
  yield takeLatest(deleteFacilityRequest, deleteFacility);
}

function* deleteProvider({ payload: { id } }) {
  try {
    yield savingApi.deleteSavedProvider(id);

    yield put(deleteProviderSuccess({ id }));
  } catch (error) {
    yield put(deleteProviderError({ error }));
  }
}

export function* deleteProviderWatcher() {
  yield takeLatest(deleteProviderRequest, deleteProvider);
}

function* fetchItem({ payload: { id, type } }) {
  let shouldDeleteItem = false;

  try {
    const result = yield api.fetchItem({ id, type });
    const planNetworkMaps = yield select(
      profile.selectors.mdvPlanNetworkMapsSelector,
    );
    const savedItemIds = yield select(getSavedItemIds);

    /* if item no longer exists */
    if (result.respStatusCode === 400) {
      shouldDeleteItem = true;

      throw new Error(
        `${I18n.t(`findMedicalCare.error.errorFetchingProvider`)}`,
      );
    }

    yield put(
      fetchItemSuccess({
        item:
          type === SEARCH_TYPES.PROVIDER
            ? normalizeProvider(result, planNetworkMaps, savedItemIds)
            : normalizeLocation(result, planNetworkMaps, savedItemIds),
      }),
    );
  } catch (error) {
    yield put(
      fetchItemError({
        errorFetchingItem: error.message || 'Could not get item',
        shouldDeleteItem,
      }),
    );
  }
}

function* fetchSavedProviders() {
  try {
    const savedProviders = yield savingApi.fetchSavedProviders();

    yield put(fetchSavedProvidersSuccess({ savedProviders }));
  } catch (error) {
    yield put(
      fetchSavedProvidersError({
        errorFetchingSavedProviders:
          error.message || 'Could not fetch saved providers',
      }),
    );
  }
}

export function* fetchSavedProvidersWatcher() {
  yield takeLatest(fetchSavedProvidersRequest, fetchSavedProviders);
}

export function* fetchItemWatcher() {
  yield takeLatest(fetchItemRequest, fetchItem);
}

function* saveProvider({ payload: { provider } }) {
  try {
    const savedProvider = yield savingApi.saveProvider(provider);

    yield put(saveProviderSuccess({ provider: savedProvider }));
  } catch (error) {
    yield put(
      saveProviderError({
        errorSavingProvider: error.message || 'Could not save provider',
      }),
    );
  }
}

export function* saveProviderWatcher() {
  yield takeLatest(saveProviderRequest, saveProvider);
}
