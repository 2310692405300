/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { auth } from '@benniehealth/core-js';

import { setState } from '@app/helpers/store';

const initialState = {
  isCheckingCompanyAccess: false,
  hasCompanyAccess: null,
  error: null,
  companies: [],
  isSuperAdmin: null,
  isImpersonationSession: null,
};

const companyAccessesSlice = createSlice({
  name: 'companyAccesses',
  initialState,
  reducers: {
    checkCompanyAccessRequest: state => {
      state.isCheckingCompanyAccess = true;
    },
    checkCompanyAccessSuccess: (state, { payload }) => {
      state.isCheckingCompanyAccess = false;
      state.hasCompanyAccess = payload;
    },
    checkCompanyAccessError: (state, { payload }) => {
      state.isCheckingCompanyAccess = false;
      state.hasCompanyAccess = false;
      state.error = payload.error;
    },
    getCompanyAccessRequestSuccess: (state, { payload }) => {
      state.allCompanies = payload.allCompanies;
      state.currentCompanySubdomain = payload.currentCompanySubdomain;
    },
    setUserType: (state, { payload }) => {
      state.isSuperAdmin = payload.isSuperAdmin;
      state.isImpersonationSession = payload.isImpersonationSession;
    },
  },
  extraReducers: builder => {
    builder.addCase(auth.actions.authOktaLogOutSuccess, state => {
      setState(state, {
        ...initialState,
      });
    });
  },
});

const { actions, reducer } = companyAccessesSlice;

export const {
  checkCompanyAccessRequest,
  checkCompanyAccessSuccess,
  checkCompanyAccessError,
  getCompanyAccessRequestSuccess,
  setUserType,
} = actions;

export const selectCompanyAccesses = createSelector(
  state => state.companyAccesses,
  companyAccesses => companyAccesses,
);

export const selectCompanyAccessesIsImpersonationSession = createSelector(
  selectCompanyAccesses,
  companyAccesses => companyAccesses.isImpersonationSession,
);

export default reducer;
