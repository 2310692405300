import { coreClient as client } from '@app/helpers/requests/client';
import {
  getResponseData,
  responseFailed,
} from '@app/helpers/requests/bennieFetch';

export const fetchSavedProviders = async () => {
  const response = await client.get(`/api/ui-services/providers`, {
    baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL,
  });

  const { status } = response;

  if (responseFailed(status)) {
    throw new Error(response.statusText || 'Error fetching saved providers');
  }

  return getResponseData(response);
};

export const saveProvider = async provider => {
  const response = await client.post(
    `/api/ui-services/providers`,
    { ...provider },
    {
      baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL,
    },
  );
  const { status } = response;

  if (responseFailed(status)) {
    throw new Error(response.statusText || 'Error saving provider');
  }

  return getResponseData(response);
};

export const deleteSavedProvider = async id => {
  const response = await client.delete(`/api/ui-services/providers/${id}`, {
    baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL,
  });

  const { status } = response;

  if (responseFailed(status)) {
    throw new Error(response.statusText || 'Could not delete saved provider');
  }

  return getResponseData(response);
};
