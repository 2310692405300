import { defaultCardList } from '@app/constants/telehealth';
import { coreClient as client } from '@app/helpers/requests/client';
import { fetchTelehealthLogoQuery } from '@app/api/contentful';
import { responseFailed } from '@app/helpers/requests/bennieFetch';

export const fetchTelehealthConfiguration = async token => {
  let telehealthconfiguration;
  try {
    const response = await client.get(`/v1/profile/telehealthconfiguration`);
    const { status, data } = response;
    telehealthconfiguration =
      responseFailed(status) || data.length === 0 ? defaultCardList : data;
  } catch (e) {
    telehealthconfiguration = defaultCardList;
  }

  const providerIds = telehealthconfiguration.map(
    provider => provider.telehealthProvider,
  );

  // logic the fetch logos from contentful
  const res = await fetchTelehealthLogoQuery({ token, providerIds });
  const logoArr = (await res.json()).data.telehealthLogoCollection.items;

  const finalData = telehealthconfiguration.map(teleConfig => {
    const tmp = logoArr.find(
      l => l.providerId === teleConfig.telehealthProvider,
    );

    return {
      ...teleConfig,
      contentfulLogo: {
        url: tmp?.providerLogo?.url,
        description: tmp?.providerLogo?.description,
        width: tmp?.providerLogo?.width,
      },
    };
  });

  return finalData;
};
