import { createSlice } from '@reduxjs/toolkit';

import { setState } from '@app/helpers/store';
import { PERMISSION_STATUS } from '@app/constants/location';

const locationSlice = createSlice({
  name: 'location',
  initialState: {
    hasDetectedCurrentLocation: false,
    hasCheckedPermissions: false,
    errorDetectingCurrentLocation: null,
    isDetectingCurrentLocation: false,
    isCheckingPermissions: false,
    isSettingPostalCode: false,
    latitude: null,
    longitude: null,
    status: null,
    postalCode: null,
  },
  reducers: {
    checkLocationPermissionsRequest(state) {
      setState(state, { isCheckingPermissions: true });
    },
    checkLocationPermissionsSuccess(state) {
      setState(state, {
        isCheckingPermissions: false,
        isDetectingCurrentLocation: true,
        hasCheckedPermissions: true,
        hasDetectedCurrentLocation: false,
        errorDetectingCurrentLocation: null,
        status: PERMISSION_STATUS.GRANTED,
      });
    },
    checkLocationPermissionsPrompt(state) {
      setState(state, {
        status: PERMISSION_STATUS.PROMPT,
      });
    },
    checkLocationPermissionsDenied(state) {
      setState(state, {
        isCheckingPermissions: false,
        status: PERMISSION_STATUS.DENIED,
        hasCheckedPermissions: true,
      });
    },
    setCurrentLocationSuccess(
      state,
      {
        payload: { latitude, longitude },
      },
    ) {
      setState(state, {
        latitude,
        longitude,
        isDetectingCurrentLocation: false,
        hasDetectedCurrentLocation: true,
      });
    },
    setCurrentLocationError(state, { payload: error }) {
      setState(state, {
        isDetectingCurrentLocation: false,
        errorDetectingCurrentLocation: error,
      });
    },
    setPostalCodeRequest(state) {
      setState(state, {
        error: null,
        isSettingPostalCode: true,
      });
    },
    setPostalCodeSuccess(
      state,
      {
        payload: { postalCode },
      },
    ) {
      setState(state, {
        postalCode,
        isSettingPostalCode: false,
      });
    },
  },
});

const { actions, reducer } = locationSlice;

export const {
  checkLocationPermissionsRequest,
  checkLocationPermissionsSuccess,
  checkLocationPermissionsDenied,
  checkLocationPermissionsPrompt,
  setCurrentLocationSuccess,
  setCurrentLocationError,
  setPostalCodeRequest,
  setPostalCodeSuccess,
} = actions;

export default reducer;
