import { put, takeLatest } from 'redux-saga/effects';

import * as apiAdmin from '@app/api/admin';
import {
  requestAvailableCompanyExports,
  companyExportsLoadSuccess,
  companyExportsLoadError,
} from './index';

export function* requestCompanyExports({ payload: { companyId } }) {
  try {
    const result = yield apiAdmin.getAvailableExports(companyId);

    if (result) {
      yield put(companyExportsLoadSuccess({ ...result }));
    } else {
      throw result;
    }
  } catch (error) {
    yield put(
      companyExportsLoadError({ error: error.message || 'not allowed' }),
    );
  }
}

export function* adminRequestAvailableCompanyExportsWatcher() {
  yield takeLatest(requestAvailableCompanyExports, requestCompanyExports);
}
